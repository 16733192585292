import React, { useState } from "react"
import emailjs from 'emailjs-com';
import { SpinnerCircular } from 'spinners-react';
import "spinners-react/lib/SpinnerCircular.css"

const ConversationForm = () => {

    const [from_name, setName] = useState('');
    const [from_email, setEmail] = useState('');
    const [app_type, setAppType] = useState('Web application');
    const [business_type, setBusinessType] = useState('Business I own');

    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessmesage, setShowSuccessmesage] = useState(false);

    function sendEmail(e) {
        e.preventDefault();
        setShowLoader(true);

        let YOUR_SERVICE_ID = process.env.EMAILJS_SERVICE_ID;
        let YOUR_TEMPLATE_ID = process.env.EMAILJS_CONVERSATION_TEMPLATE_ID;
        let YOUR_USER_ID = process.env.EMAILJS_USER_ID;

        emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_USER_ID)
            .then((result) => {
                //console.log(result.text);
                setShowLoader(false);
                if (result.text == 'OK') {
                    setShowSuccessmesage(true);
                    setName('');
                    setEmail('');
                    setAppType('Web application');
                    setBusinessType('Business I own');

                    setTimeout(() => {
                        setShowSuccessmesage(false);
                    }, 3000)
                }
            }, (error) => {
                console.log(error.text);
                setShowLoader(false);
                alert('Something went wrong')
            });
    }

    return (
        <section className="pageContactWrap">
            <div className="wrapper">
                <h3><span>Want to start a conversation?</span></h3>
                <form name="contact" onSubmit={sendEmail}>
                    <div className="contactBlk">
                        <label>Yes, I would like to chat about a </label>
                        <select className={app_type != ''? 'haveValue': ''} name="app_type" onChange={e => setAppType(e.target.value)} value={app_type}>
                            <option value="Web application">Web application</option>
                            <option value="Mobile app">Mobile app</option>
                            <option value="Website">Website</option>
                            <option value="Product idea">Product idea</option>
                        </select>
                        <label>project. It is for my </label>
                        <select className={business_type != ''? 'haveValue': ''} name="business_type" onChange={e => setBusinessType(e.target.value)} value={business_type}>
                            <option value="Business I own">Business I own</option>
                            <option value="Client">Client</option>
                            <option value="Startup I am thinking about">Startup I am thinking about</option>
                            <option value="Something else">Something else</option>
                        </select>
                        <label>My name is</label>
                        <input type="text" className={from_name != ''? 'haveValue': ''} name="from_name" onChange={e => setName(e.target.value)} value={from_name} placeholder="Name *" required />
                        <label>Contact me at</label>
                        <input type="email" className={from_email != ''? 'haveValue': ''} name="from_email" onChange={e => setEmail(e.target.value)} value={from_email} placeholder="email@example.com *" required />
                    </div>
                    <p>By clicking Submit, you agree to the Digital Avenues <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                    <button type="submit" className="btnStyle1">
                        {!showLoader &&
                            <span>Submit</span>
                        }
                        {showLoader &&
                            <SpinnerCircular size={28} thickness={144} speed={173} color="rgba(57, 172, 129, 1)" secondaryColor="rgba(0, 0, 0, 1)" />
                        }
                    </button>
                    {showSuccessmesage &&
                        <div className="successMsg mt16">Thank you for getting in touch! </div>
                    }
                </form>
            </div>
        </section>
    )


}

export default ConversationForm
