import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/layout";
import ConversationForm from "../components/conversationForm";
import SEO from "../components/seo";


const OurWork = () => {
    const servicesListQuery = useStaticQuery(graphql`query {
        allStrapiServices(limit: 100) {
            edges {
                node {
                  id
                  name
                  projects {
                    id
                    title
                    slug
                    feature_description
                    has_case_study
                    cover_image_large {
                      publicURL
                    }
                  }
                }
              }
            }
            allStrapiProjects {
                edges {
                  node {
                    id
                    title
                    slug
                    services {
                      name
                    }
                  }
                }
            }
        }`);

    const [servicesList, setServicesList] = useState(null);
    const [allProjects, setAllProjects] = useState([]);

    useEffect(() => {
        setAllServices();
    }, []);

    function setAllServices(){
        const allNode = {
            node: {
              id: null,
              name: "All",
              projects: []
            },
            selected: true
        };

        let allServices = servicesListQuery.allStrapiServices.edges;
        allServices.forEach((element, index) => {
            element.selected = false;
        });
        const totalServices = [allNode, ...allServices];
        setServicesList(totalServices);

        // Set all projects
        const allProjectsData = servicesListQuery.allStrapiProjects.edges;
        setAllProjects(allProjectsData);
    }

    function selectService(serviceIndex){
        let currentServicesList = [...servicesList];
        currentServicesList.forEach(element => { element.selected = false; });
        currentServicesList[serviceIndex].selected = true;

        setServicesList(currentServicesList);
    }

    function getServiceProject(services_list){
        let projects = [];

        if(services_list != null){
            let isAllselected = services_list[0].selected;
            services_list.forEach((element, index) => {
                if(element.node.id != null){
                    if(isAllselected){
                        projects = [...projects, ...element.node.projects];
                    }
                    else{
                        if(element.selected){
                            projects = [...projects, ...element.node.projects];
                        }
                    }
                }
            });
        }

        const arrayUniqueById = [...new Map(projects.map(item =>
        [item['id'], item])).values()];

        return chunkProject(arrayUniqueById, 18);
        //return arrayUniqueById;
    }

    function chunkProject(arr, size){
        const chunkAtt = Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );
        return chunkAtt;
    }

    function makeServiceString(projectTitle){
        let serviceString = '';
        let foundProject = allProjects.find(obj => obj.node.title === projectTitle);
        if(foundProject){
            const serviceArray = foundProject.node.services;
            if(serviceArray && serviceArray != null && serviceArray != ''){
                let serviceValueArray = [];
                serviceArray.map((service) =>
                    serviceValueArray.push(service.name)
                );
                serviceString = serviceValueArray.join(', ');
            }
        }

        return serviceString;
    }

    return (
    <Layout>
        <SEO title="Our Work" />
        <div className="contentWrapper">
            <section className="portfolioWrap">
                <div className="wrapper">
                <div className="outerHeading">Our Expertise</div>
                <h2 className="mainHeading">Portfolio</h2>
                <div className="techNav">

                    {servicesList != null && servicesList.length &&
                        <ul>
                        {
                            servicesList.map((service, index) => {
                                return(
                                    <li className={service.selected?'active':''} key={index}>
                                        <span onClick={() => selectService(index)}>{service.node.name}</span>
                                    </li>
                                )
                            })
                        }
                        </ul>
                    }
                    
                </div>
                <div className="portfolioBlkWrap">
                {servicesList != null && servicesList.length &&

                    getServiceProject(servicesList).map((projectChunk, index) => {
                        return (
                            <ul key={index}>
                                {
                                    projectChunk.map((project, index) => {
                                        return(
                                            <li key={index}>
                                                <Link to={'/our-work/'+project.slug} >
                                                <sapn className="portfolioBlkImg">                                                    
                                                    <img src={project.cover_image_large.publicURL} alt="Cleversort" />
                                                </sapn>
                                                <sapn className="portfolioBlkDesc">
                                                    <h3>{project.title}</h3>
                                                    <p>{makeServiceString(project.title)}</p>
                                                </sapn>
                                                {project.has_case_study && 
                                                    <span className="caseStudy">Case Study</span>
                                                }
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        )
                    })
                }
                </div>
                </div>
            </section>
            <ConversationForm />
        </div>
    </Layout>
  )
}


export default OurWork
